const listenerTypeFriendly = (listener) => {
  switch (listener.type) {
    case 'errorGroup':
      return `Alert if error group contains errors`;
    case 'successFailRatio':
      return `Alert if success/fail ratio ${listener.direction} than ${listener.ratio}%`;
    case 'volumePeriod':
      return `Alert if volume ${listener.direction} than ${listener.amount} over ${listener.periodAmount} ${listener.periodType}${listener.periodAmount !== 1 ? 's' : ''}`;
    case 'volumeTotal':
      return `Alert if volume ${listener.direction} than ${listener.amount}`;
    default:
      return `Unknown type ${listener.type}`;
  }
};

const systemList = [
  {text: 'CPD Hub', value: 'cpd-hub'},
  {text: 'Portal', value: 'portal'},
];

export default { listenerTypeFriendly, systemList };

<template>
  <v-dialog v-model="dialog" max-width="700px" @click:outside="$emit('close')" @keydown.esc="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="text-h5">
          Create a new branch
        </span>
      </v-card-title>
      <v-alert
        v-if="brachNameExists"
        border="top"
        color="red lighten-2"
        class="ma-5"
        dark
      >
        The linked item on the Monday board has a branch: {{mondayItem && mondayItem.branch}}.
        <br/>
        Creating a branch with a different name will overwrite it.
      </v-alert>
      <v-card class="pa-5">
        <v-text-field
          v-model="newBranchName"
          counter="40"
          label="Name of the new branches"
        />
        <v-autocomplete
          v-model="repos"
          :items="reposItems"
          item-text="name"
          label="Github Repo"
          outlined
          chips
          small-chips
          dense
          filled
          multiple
        />
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="create">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import code from "@/model/code/Code"
const Github = require('@/API/Github/Api.js');

export default {
  name: "NewBranchDialog",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    reposItems: {
      type: Array,
      default: () => []
    },
    mondayItem: {
      type: Object
    }
  },
  data() {
    return {
      newBranchName: undefined,
      repos: []
    };
  },
  computed: {
    brachNameExists() {
      return this.mondayItem?.branch && this.newBranchName !== this.mondayItem?.branch
    }
  },
  watch: {
    mondayItem: {
      handler() {
        this.newBranchName = this.mondayItem && this.mondayItem.branchName;
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async create() {
      const reposNames = this.$store.state.repos.reduce((obj, item) => (obj[item.name] = {default_branch: item.default_branch, default_branch_sha: item.default_branch_sha, tag: item.tag || "undefined"}, obj) ,{});
      for (const repo of this.repos) {
        try {
          await Github.createBranch(repo, this.newBranchName, reposNames[repo].default_branch_sha).then(res=>console.warn("res", res))
        } catch (err) {
          const regex = new RegExp('Bad credentials', 'g');
          const found = regex.test(err);
          if(found){
            localStorage.removeItem('gh_token')
            this.$store.commit('SET_TOKEN', '')
            this.$notify({
              type: "error",
              text: `Bad credentials, most likely expired token go to main page to reset it`,
            });
          }else{
            console.error(err)
            this.$notify({
              type: "error",
              text: `There has been an error: ${err}`,
            });
          }
        }
      }

      try {
        code.updateItemMonday(this.newBranchName, this.mondayItem.id).then(res=>console.warn("res", res))
      } catch (err) {
        console.error(err, "error");
        alert(`Error: ${err}`);
      }
      this.$emit("closing");
    },
  }
};
</script>

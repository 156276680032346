<template>
  <welfare-project-listener-base-dialog
    :id="id"
    :project-id="projectId"
    dialog-title="Success/Fail Ratio Listener"
    type="successFailRatio"
  >
    <template v-slot:activator="{open}">
      <slot :open="open" />
    </template>
    <div>
      <v-select
        v-model="direction"
        :items="[{text: 'Greater than', value: 'greater'}, {text: 'Less than', value: 'less'}]"
        label="Alert if ratio is..."
        :rules="directionRules"
      />
      <v-text-field
        v-model="ratio"
        :rules="ratioRules"
        label="Ratio %"
      />
      <v-text-field
        v-model="lastAttempts"
        :rules="lastAttemptsRules"
        label="Over last x attempts"
      />
    </div>
  </welfare-project-listener-base-dialog>
</template>

<script>
import WelfareProjectListenerBaseDialog from "@/components/Welfare/Project/Listener/BaseDialog";

export default {
  components: {WelfareProjectListenerBaseDialog},
  name: "WelfareProjectListenerSuccessFailRatioDialog",
  props: {
    id: {
      type: String,
      default: null,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modelField: ["direction", "lastAttempts", "ratio"],
      direction: "",
      directionRules: [
        v => !!v || "Please select a direction",
      ],
      lastAttempts: "",
      lastAttemptsRules: [
        v => !!v || "Please enter an amount of attempts",
        v => !isNaN(v) || "Please enter a numerical amount of attempts",
        v => v >= 1 || "Last attempts much be greater then 1",
      ],
      ratio: "",
      ratioRules: [
        v => !!v || "Please enter a ratio",
        v => !isNaN(v) || "Please enter a numerical ratio",
        v => v >= 0 || "Please enter a positive ratio",
        v => v <= 100 || "Please enter a ratio less then 100%",
      ],
    };
  },
};
</script>

<template>
  <page-loader v-if="teamList === null" />
  <v-card v-else>
    <v-card-title>
      Welfare Project List
      <v-spacer/>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        hide-details
      />
      <v-spacer/>
      <welfare-project-upsert-dialog>
        <template v-slot="{open}">
          <v-btn
            color="green"
            dark
            @click="open"
          >
            <v-icon class="mr-2" small>fal fa-plus</v-icon>
            Add Project
          </v-btn>
        </template>
      </welfare-project-upsert-dialog>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="projectList"
      :search="search"
    >
      <template v-slot:item.team="{ item }">
        {{ teamName(item) }}
      </template>
      <template v-slot:item.code="{ item }">
        <v-btn
          small
          text
          :to="{
                  name: 'welfare-project-detail',
                  params: { projectID: item.id }
                }">
          {{ item.code }}
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {db} from "@/firestore";
import PageLoader from "@/components/Page/Loader";
import WelfareProjectUpsertDialog from "@/components/Welfare/Project/UpsertDialog";

export default {
  name: "PageWelfareTeamList",
  components: {WelfareProjectUpsertDialog, PageLoader},
  data() {
    return {
      headers: [
        {text: "Code", value: "code", sortable: false},
        {text: "Name", value: "name"},
        {text: "Team", value: "team"},
      ],
      search: "",
      projectList: [],
      teamList: [null],
    };
  },
  methods: {
    teamName(project) {
      const team = this.teamList.find(t => t.id === project.team);
      return team ? team.name : `Unknown (${project.team})`;
    },
  },
  firestore: {
    projectList: db.collection("welfare-project"),
    teamList: db.collection("welfare-team"),
  },
};
</script>

<template>
  <page-loader v-if="!team"/>
  <v-row v-else>
    <v-col :xl="3" :lg="4" :md="5" :sm="12">
      <v-card>
        <v-card-title>
          Welfare Team: {{ team.name }}
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <welfare-team-upsert-dialog :id="team.id">
            <template v-slot="{open}">
              <v-btn
                @click="open"
              >
                <v-icon class="mr-2" small>fal fa-pencil</v-icon>
                Edit Team
              </v-btn>
            </template>
          </welfare-team-upsert-dialog>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col :xl="9" :lg="8" :md="7" :sm="12">
      <v-card>
        <v-card-title>
          Team Members
          <v-spacer />
          <welfare-team-member-upsert-dialog :team-id="teamID">
            <template v-slot="{open}">
              <v-btn
                color="green"
                dark
                @click="open"
              >
                <v-icon class="mr-2" small>fal fa-plus</v-icon>
                Add Team Member
              </v-btn>
            </template>
          </welfare-team-member-upsert-dialog>
        </v-card-title>
        <v-data-table
          :headers="memberHeader"
          :items="memberList"
        >
          <template v-slot:item.edit="{ item }">
            <welfare-team-member-upsert-dialog :team-id="teamID" :id="item.id">
              <template v-slot="{open}">
                <v-btn
                  icon
                  @click="open"
                >
                  <v-icon class="mr-2" small>fal fa-pencil</v-icon>
                </v-btn>
              </template>
            </welfare-team-member-upsert-dialog>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {db} from "@/firestore";
import dataWelfare from "@/data/welfare";
import WelfareTeamUpsertDialog from "@/components/Welfare/Team/UpsertDialog";
import PageLoader from "@/components/Page/Loader";
import WelfareTeamMemberUpsertDialog from "@/components/Welfare/Team/Member/UpsertDialog";

export default {
  name: "PageWelfareTeamDetail",
  components: {WelfareTeamMemberUpsertDialog, PageLoader, WelfareTeamUpsertDialog},
  data() {
    return {
      memberHeader: [
        {text: "Edit", value: "edit", width: 50, sortable: false},
        {text: "Name", value: "name"},
        {text: "Email", value: "email"},
      ],
      memberList: null,
      team: null,
    };
  },
  computed: {
    teamID() {
      return this.$route.params.teamID;
    },
  },
  firestore() {
    return {
      memberList: db.collection("welfare-team").doc(this.teamID).collection("member"),
      team: db.collection("welfare-team").doc(this.teamID),
    };
  },
};
</script>

<style scoped>

</style>

import { functions } from '@/firestore';

export default class {
  static updateItemMonday(newBranchName, mondayItemId) {
    return new Promise((resolve, reject) => {
      const updateItemMonday = functions.httpsCallable('shepherd/updateItemMonday');
      updateItemMonday({newBranchName, mondayItemId })
        .then(result => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  }

  static accessToken(code) {
    return new Promise((resolve, reject) => {
      const accessToken = functions.httpsCallable('shepherd/accessToken');
      accessToken({ code })
        .then(result => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  }

  static mergeTeam() {
    return new Promise((resolve, reject) => {
      const mergeTeam = functions.httpsCallable('shepherd/mergeTeam');
      mergeTeam()
        .then(result => {
          resolve(result.data);
        })
        .catch((err) => {
          reject();
        });
    });
  }
}

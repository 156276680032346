<template>
  <page-loader v-if="!project || !teamList || listenerList === null"/>
  <v-row v-else>
    <v-col :xl="3" :lg="4" :md="5" :sm="12">
      <v-card>
        <v-card-title>
          Welfare Project: {{ project.name }}
        </v-card-title>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Code</v-list-item-title>
              <v-list-item-subtitle>{{ project.code }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Team</v-list-item-title>
              <v-list-item-subtitle>{{ teamName }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer/>
          <welfare-project-upsert-dialog :id="project.id">
            <template v-slot="{open}">
              <v-btn
                @click="open"
              >
                <v-icon class="mr-2" small>fal fa-pencil</v-icon>
                Edit Project
              </v-btn>
            </template>
          </welfare-project-upsert-dialog>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col :xl="9" :lg="8" :md="7" :sm="12">
      <v-card class="mb-6">
        <v-card-title>
          Project Listeners
          <v-spacer/>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-4"
                color="primary"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>fas fa-plus</v-icon>
              </v-btn>
            </template>
            <v-list>
              <welfare-project-listener-error-group-dialog :project-id="projectID">
                <template v-slot="{open}">
                  <v-list-item
                    @click="open"
                  >
                    <v-list-item-title>Add Error Group Listener</v-list-item-title>
                  </v-list-item>
                </template>
              </welfare-project-listener-error-group-dialog>
              <welfare-project-listener-success-fail-ratio-dialog :project-id="projectID">
                <template v-slot="{open}">
                  <v-list-item
                    @click="open"
                  >
                    <v-list-item-title>Add Success Fail Ratio Listener</v-list-item-title>
                  </v-list-item>
                </template>
              </welfare-project-listener-success-fail-ratio-dialog>
              <welfare-project-listener-volume-period-dialog :project-id="projectID">
                <template v-slot="{open}">
                  <v-list-item
                    @click="open"
                  >
                    <v-list-item-title>Add Volume over Period Listener</v-list-item-title>
                  </v-list-item>
                </template>
              </welfare-project-listener-volume-period-dialog>
              <welfare-project-listener-volume-total-dialog :project-id="projectID">
                <template v-slot="{open}">
                  <v-list-item
                    @click="open"
                  >
                    <v-list-item-title>Add Volume Total Listener</v-list-item-title>
                  </v-list-item>
                </template>
              </welfare-project-listener-volume-total-dialog>
            </v-list>
          </v-menu>

        </v-card-title>
        <v-data-table
          :headers="listenerHeaders"
          :items="listenerList"
          :search="listenerSearch"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:item.code="{ item }">
            <v-btn depressed
               :to="{
                  name: 'welfare-project-listener',
                  params: { listenerID: item.id }
                }">
              {{ item.code }}
            </v-btn>
          </template>
          <template v-slot:item.type="{ item }">
            {{ listenerTypeFriendly(item) }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {db} from "@/firestore";
import PageLoader from "@/components/Page/Loader";
import WelfareProjectUpsertDialog from "@/components/Welfare/Project/UpsertDialog";
import WelfareProjectListenerVolumePeriodDialog
  from "@/components/Welfare/Project/Listener/VolumePeriodDialog";
import WelfareProjectListenerErrorGroupDialog
  from "@/components/Welfare/Project/Listener/ErrorGroup";
import WelfareProjectListenerSuccessFailRatioDialog
  from "@/components/Welfare/Project/Listener/SuccessFailRatioDialog";
import dataWelfare from "@/data/welfare";
import WelfareProjectListenerVolumeTotalDialog
  from "@/components/Welfare/Project/Listener/VolumeTotal";

export default {
  name: "PageWelfareTeamDetail",
  components: {
    WelfareProjectListenerVolumeTotalDialog,
    WelfareProjectListenerSuccessFailRatioDialog,
    WelfareProjectListenerErrorGroupDialog,
    WelfareProjectListenerVolumePeriodDialog, WelfareProjectUpsertDialog, PageLoader},
  data() {
    return {
      listenerList: null,
      listenerSearch: "",
      listenerHeaders: [
        {text: "Code", value: "code"},
        {text: "Name", value: "name"},
        {text: "Type", value: "type"},
      ],
      project: null,
      sortBy: 'code',
      sortDesc: false,
      teamList: null,
    };
  },
  computed: {
    projectID() {
      return this.$route.params.projectID;
    },
    teamName() {
      const team = this.teamList.find(t => t.id === this.project.team);
      return team ? team.name : `Unknown (${this.project.team})`;
    },
  },
  methods: {
    listenerTypeFriendly(listener) {
      return dataWelfare.listenerTypeFriendly(listener);
    },
  },
  firestore() {
    return {
      listenerList: db.collection("welfare-listener").where("projectId", "==", this.projectID),
      project: db.collection("welfare-project").doc(this.projectID),
      teamList: db.collection("welfare-team"),
    };
  },
};
</script>

<style scoped>

</style>

<template>
  <page-upsert-dialog
    ref="dialog"
    :error="error"
    :mode="mode"
    :title="title"
    @clear="clear"
    @save="save"
  >
    <template v-slot:activator="{open}">
      <slot :open="open"/>
    </template>
    <page-loader v-if="teamList === null"/>
    <v-form
      v-else
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Project Name"
      />
      <v-text-field
        v-if="mode === 'create'"
        v-model="code"
        :rules="codeRules"
        label="Project Code"
      />
      <v-autocomplete
        v-model="team"
        :items="teamList"
        item-text="name"
        item-value="id"
        label="Project Team"
        :rules="teamRules"
      />
    </v-form>
  </page-upsert-dialog>
</template>

<script>
import {db} from "@/firestore";
import PageUpsertDialogBase from "@/components/Page/UpsertDialog.base";
import PageLoader from "@/components/Page/Loader";
import _ from "lodash";

export default {
  components: {PageLoader},
  extends: PageUpsertDialogBase,
  name: "WelfareProjectUpsertDialog",
  data() {
    return {
      modelField: ["code", "name", "team"],
      code: "",
      codeTaken: false,
      codeRules: [
        v => !!v || "Please enter a project code",
        v => !!v.match(/^[A-Z]+[A-Z-]*[A-Z]+$/) || "Project code must contain upper case letters and dashes only",
        v => this.codeTaken === true || this.codeTaken,
      ],
      name: "",
      nameTaken: false,
      nameRules: [
        v => !!v || "Please enter a project name",
        v => this.nameTaken === true || this.nameTaken,
      ],
      team: "",
      teamRules: [
        v => !!v || "Please select a team",
      ],
      teamList: null,
      title: "Project",
    };
  },
  methods: {
    create(resolve) {
      db.collection("welfare-project").
        add(this.modelData).
        then((document) => {
          resolve(document.id);
        });
    },
    edit(resolve) {
      const data = this.modelData;
      delete data.code;
      db.collection("welfare-project").
        doc(this.id).
        update(data).
        then(() => {
          resolve(this.id);
        });
    },
    lookup(resolve, reject) {
      db.collection("welfare-project").
        doc(this.id).
        get().
        then((data) => {
          if (!data.exists) {
            reject();
          } else {
            resolve(data.data());
          }
        });
    },
  },
  watch: {
    code: _.debounce(function(value) {
      const firestore = db;
      this.codeTaken  = true;
      if (this.$refs.form && value !== "") {
        this.$refs.form.validate();
        firestore.collection("welfare-project").
          where("code", "==", value.trim()).
          get().
          then((existing) => {
            if (existing.size > 0 && existing.docs[0].id !== this.id) {
              this.codeTaken = "A project with this code already exists";
              this.$refs.form.validate();
            }
          });
      }
    }, 250),
    name: _.debounce(function(value){
      const firestore = db;
      this.nameTaken  = true;
      if (this.$refs.form && value !== "") {
        this.$refs.form.validate();
        firestore.collection("welfare-project").
          where("name", "==", value.trim()).
          get().
          then((existing) => {
            if (existing.size > 0 && existing.docs[0].id !== this.id) {
              this.nameTaken = "A project with this name already exists";
              this.$refs.form.validate();
            }
          });
      }
    }, 250),
  },
  firestore() {
    return {
      teamList: db.collection("welfare-team"),
    };
  },
};
</script>
